*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  user-select: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Ubuntu', sans-serif; */
}

svg {
  max-width: 100%;
}

:root {
  font-size: 10px;
}

*::-webkit-scrollbar {
  /* background: rgba(51, 51, 51, 0.0); */
  background: transparent;
  width: 3px;
}
*::-webkit-scrollbar-thumb {
  background: rgba(51, 51, 51, 0.15);
}

button,
button::before,
button::after,
button:focus،
button:active,
button:target {
  outline: none;
  background: transparent;
  border: none;
  box-shadow: none;
}

button::-moz-focus-inner {
  border: 0;
}

svg,
svg::before,
svg::after,
svg:focus،
svg:active,
svg:target {
  outline: none;
  background: transparent;
  border: none;
  box-shadow: none;
}
