*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  -webkit-user-select: none;
          user-select: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Ubuntu', sans-serif; */
}

svg {
  max-width: 100%;
}

:root {
  font-size: 10px;
}

*::-webkit-scrollbar {
  /* background: rgba(51, 51, 51, 0.0); */
  background: transparent;
  width: 3px;
}
*::-webkit-scrollbar-thumb {
  background: rgba(51, 51, 51, 0.15);
}

button,
button::before,
button::after,
button:focus،
button:active,
button:target {
  outline: none;
  background: transparent;
  border: none;
  box-shadow: none;
}

button::-moz-focus-inner {
  border: 0;
}

svg,
svg::before,
svg::after,
svg:focus،
svg:active,
svg:target {
  outline: none;
  background: transparent;
  border: none;
  box-shadow: none;
}

.Player {
    padding: 24px 28px;
    max-height: 100vh;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-flow: column;
    position: relative;
    background: aliceblue;
}

.Player .ctr-1 {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
}

.Player .ctr-1 .play-pause-text {
    font-size: 1.2rem;
    letter-spacing: 2px;
    padding-left: 2px;
}

.Player .ctr-1 .play-pause-text strong {
    font-weight: normal;
    color: #333;
    margin-right: 15px;
}

.Player .ctr-1 .play-pause-text span {
    font-weight: bold;
    color: #333;
    margin-right: 15px;
}

.Player .ctr-1 button {
    width: 35px;
    height: 35px;
    display: block;
    background: transparent;
    outline: none;
    border: none;
    padding: 5px;
    padding-right: 0;
    padding-left: 10px;
}

.Player .ctr-1 button svg {
    fill: tomato;
}

.Player .track-title {
    font-size: 2.6rem;
    color: #333;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 2ch;
    margin-top: 0.5ch;
    max-height: 9ch;
    overflow: auto;
}

.Player .bottom-ctr {
    position: absolute;
    bottom: 24px;
    left: 28px;
    right: 28px;
}

.Player .progress-container {
    position: relative;
    margin-bottom: 32px;
    padding-bottom: 44px;
}

.Player .progress-container .progress-bar {
    width: 100%;
    height: 3px;
    border-radius: 100000px;
    background: transparent;
    overflow: hidden;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.Player .progress-container .progress-bar .done {
    background: #333;
    border-radius: 100000px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 100%;
    z-index: 11;
}

.Player .progress-container .progress-bar .remaining {
    background: #333;
    opacity: 0.2;
    border-radius: 100000px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.Player .progress-container .handle-ctr {
    position: absolute;
    width: 0px;
    overflow: visible;
    transform: translateY(-40%);
    left: 0;
}

.Player .progress-container .handle-ctr .time-span {
    display: inline-block;
    font-size: 1rem;
    letter-spacing: 2px;
    font-weight: bold;
    text-align: center;
    background: #333;
    color: #fff;
    margin-left: 50%;
    text-align: center;
    width: auto;
    max-width: 30vw;
    padding: 4px 4px 4px 5px;
    transform: translateX(-50%);
    margin-bottom: 20px;
    position: relative;
}

.Player .progress-container .handle-ctr .time-span span {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    width: 6px;
    height: 6px;
    background: #333;
}

.Player .progress-container .handle-ctr .handle {
    display: block;
    width: 40px;
    background: transparent;
    outline: none;
    border: none;
    margin-left: 50%;
    transform: translateX(-50%);
}

.Player .progress-container .handle-ctr .handle svg {
    fill: #333;
}

.Player .control-icons-container {
    margin-bottom: 32px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-evenly;
}

.Player .control-icons-container button.play-control {
    display: block;
    outline: none;
    border: none;
    width: 56px;
    height: 56px;
    background: aliceblue;
    border-radius: 100000px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 12px;
}

.Player .control-icons-container button.play-control svg {
    fill: #333;
}

.Player .control-icons-container button.play-control.play-pause-container {
    width: 74px;
    height: 74px;
    padding: 18px;
}

.Player .volume-container {
    margin-bottom: 28px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
}

.Player .volume-container .volume-icon-ctr {
    min-width: 40px;
}

.Player .volume-container .volume-icon-ctr button {
    display: block;
    width: 22px;
    background: transparent;
    outline: none;
    border: none;
}

.Player .volume-container .volume-icon-ctr button svg {
    fill: #333;
}

.Player .volume-container .volume-pctg-ctr {
    min-width: 40px;
}

.Player .volume-container .volume-pctg-ctr span {
    display: block;
    text-align: right;
    font-weight: bold;
    color: #333;
    letter-spacing: 0px;
    font-size: 1.4rem;
    padding-bottom: 4px;
}

.Player .volume-container .volume-bar-ctr {
    width: calc(100% - 80px);
    height: 3px;
    border-radius: 100000px;
    background: transparent;
    position: relative;
    transform: translateY(-55%);
}

.Player .volume-container .volume-bar-ctr .done {
    background: #333;
    border-radius: 100000px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 100%;
    z-index: 11;
}

.Player .volume-container .volume-bar-ctr .remaining {
    background: #333;
    opacity: 0.2;
    border-radius: 100000px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.Player .volume-container .volume-bar-ctr .handle-ctr {
    position: absolute;
    width: 0px;
    transform: translateY(-41%);
    left: 0px;
    z-index: 12;
}

.Player .volume-container .volume-bar-ctr .handle {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100000px;
    padding: 4px;
    box-sizing: content-box;
    background: aliceblue;
    outline: none;
    border: none;
    border: 3px solid #333;
    margin-left: 50%;
    transform: translateX(-50%);
}

.Player .bottom-icons-ctr {
    display: flex;
    flex-flow: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.Player .bottom-icons-ctr button {
    width: 28px;
    background: transparent;
    outline: none;
    border: none;
    text-align: center;
}

.Player .bottom-icons-ctr button svg {
    fill: #333;
    opacity: 0.6;
}

.Player .bottom-icons-ctr button.active svg {
    fill: #333;
    opacity: 1;
}

.TracksList {
    padding: 24px 28px;
    padding-top: 30px;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: aliceblue;
    transform: translateX(-100%);
    transition: all 0.3s ease-out;
}

.TracksList .tracks-list-ctr {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    overflow-x: hidden;
}

.TracksList .tracks-list-ctr .tracks-list-upper-elem {
    height: 100px;
    display: flex;
    flex-flow: row;
    align-items: center;
}

.TracksList .tracks-list-ctr .tracks-list-upper-elem p {
    font-size: 1.2rem;
    letter-spacing: 3px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    color: #333;
}

.TracksList .tracks-list-ctr ul {
    list-style: none;
    height: 100%;
    /* padding-bottom: 140px; */
}

.TracksList .tracks-list-ctr ul li {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 2.1rem;
    letter-spacing: 0.1ch;
    margin: 4px 0;
    padding: 12px 0px;
    color: #333;
    font-weight: 300;
}

.TracksList .tracks-list-ctr ul li.active span {
    display: inline-block;
    padding-left: 100%;
    text-indent: 0;
    animation: marquee 15s linear infinite;
    color: rgba(51, 51, 51, 1);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

.TracksList .tracks-list-ctr ul li.active {
    color: rgba(51, 51, 51, 1);
    text-overflow: clip;
    font-weight: bold;
}

.TracksList .bottom-ctr {
    background: aliceblue;
    box-shadow: 0 -10px 18px -12px rgba(51, 51, 51, 0.15);
}

.TracksList .search-ctr {
    display: flex;
    flex-flow: column;
    margin-bottom: 30px;
}

.TracksList .search-ctr input.search-bar {
    padding: 4px 0px;
    padding-left: 0;
    padding-top: 14px;
    width: 100%;
    display: block;
    outline: none;
    border: none;
    background: aliceblue;
    font-size: 4rem;
    font-weight: 200;
    color: #333;
    border-bottom: 1px solid rgba(51, 51, 51, 0.7);
}

.TracksList .search-ctr input.search-bar:focus {
    border-bottom: 1px solid rgba(51, 51, 51, 0.3);
    border-bottom-color: tomato;
}

.TracksList .search-ctr input.search-bar::-webkit-input-placeholder {
    color: #333;
}

.TracksList .search-ctr input.search-bar::placeholder {
    color: #333;
}

.TracksList .search-ctr input.search-bar::selection {
    color: aliceblue;
    background: #333;
}

.TracksList .bottom-btns-ctr {
    display: flex;
    flex-flow: row;
    margin-bottom: 6px;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.TracksList .bottom-btns-ctr button.back-btn {
    outline: none;
    border: none;
    background: transparent;
    width: 29px;
    padding-top: 2px;
}

.TracksList .bottom-btns-ctr button.back-btn svg {
    fill: #333;
    opacity: 0.6;
}

.TracksList .bottom-btns-ctr button.favs-btn {
    outline: none;
    border: none;
    background: transparent;
    width: 25px;
}

.TracksList .bottom-btns-ctr button.favs-btn svg {
    fill: tomato;
}

